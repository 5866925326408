
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Noto+Sans:ital@1&display=swap');

body {
  
  /* color: white; */
  font-family: "Inter", sans-serif;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  margin: 1rem;
  background: whitesmoke;
  /* background: rgb(7,55,99); */
  background: linear-gradient(90deg, rgba(7,55,99,1) 0%, rgba(11,83,148,1) 50%, rgba(61,133,198,1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.white_t {
  color: white; /* Ensure this CSS class exists */
}

.mw600 {
  max-width: 600px;
}

.special-t {
  /* Text color gradient */
background: linear-gradient(90deg, rgba(7,55,99,1) 0%, rgba(11,83,148,1) 50%, rgba(61,133,198,1) 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}